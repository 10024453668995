import React, { useState, FunctionComponent } from "react";
import { useParams } from 'react-router-dom';
import { Formik, Form } from "formik";
import { Button, Paper, Box } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { Progress } from "./progress";
import { QuestionnaireForm } from "./questionnaire";

import { useQuery, useMutation, gql } from '@apollo/client';

import { PdfViewer } from "./pdf/viewer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            height: "100%",
        },
        actionsContainer: {
            textAlign: "right",
            margin: theme.spacing(2)
        },
    }),
);

const GET_VISIT = gql`
query GetVisit ($id: String!){
    visit(id :$id){
        id,
        answers {
            ... on StringAnswer{
              id
              text
            },
            ... on BooleanAnswer{
              id,
              value
            }
            ... on DimensionsAnswer{
                id,
                dimensions {
                    width
                    depth
                    height
                }
            }
            ... on ArrayAnswer{
                id,
                values {
                    appliance
                    manufacturer
                    dimensions
                    typenumber
                    fdnumber

                }
            }
        }
        questionnaire { 
            id
            logo,
            sections {
              id,
              title,
              questions {
                id,
                text,
                type,
                prefix,
                suffix,
                choices {
                  text,
                  question {
                    id,
                    text,
                    type,
                prefix,
                suffix,
                  }
                }
              }
           }
        }
      }
  }
`;

const UPDATE_VISIT = gql`
mutation UpdateVisit ($visit: UpdateVisit!){
    updateVisit(visit :$visit){
        id
    }
  }
`;


export const Visit: FunctionComponent = () => {
    const classes = useStyles();
    const { visitId } = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const variables = {
        "id": visitId
    };

    const { loading, data } = useQuery(GET_VISIT, { variables });
    const [updateVisit] = useMutation(UPDATE_VISIT);
    
    const handleNext = async (submitForm: any) => {
        await submitForm();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = async (submitForm: any) => {
        await submitForm();
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const initialValues = (answers: any[]) => {
        answers = answers ?? [];
        if (answers) {
            answers = answers.reduce(function (map, obj) {
                if ("text" in obj) {
                    map[obj.id] = obj.text;
                }
                if ("value" in obj) {
                    map[obj.id] = obj.value;
                }
                if ("values" in obj) {
                    map[obj.id] = obj.values;
                }
                if ("dimensions" in obj) {
                    map[obj.id] = obj.dimensions;
                }
                return map;
            }, {});
        }

        return {
            ...answers
        };
    };

    const save = async (values: any, setSubmitting: any) => {
        setSubmitting(true);
        var answers = [];
        for (const property of Object.keys(values)) {
            var value: any = values[property];
            if (typeof value === "string" || value instanceof String) {
                answers.push({
                    id: property,
                    type: "string",
                    value: value
                });
            }
            if (typeof value === "boolean" || value instanceof Boolean) {
                answers.push({
                    id: property,
                    type: "boolean",
                    value: value?.toString()
                });
            }

            if(value instanceof Object && !Array.isArray(value)){
                answers.push(
                    {
                        id: property, 
                        type: "dimensions",
                        value: JSON.stringify(value)
                    }
                );
            }

            if (Array.isArray(value) || value instanceof Array) {
                answers.push({
                    id: property,
                    type: "array",
                    value: JSON.stringify(value)
                });
            }   
        }

        await updateVisit({
            variables: {
                visit: {
                    id: visitId,
                    answers,
                }
            }
        });

        setSubmitting(false);
    };

    return (
        <>
            {!loading &&
                <Formik
                    initialValues={initialValues(data.visit.answers)}
                    onSubmit={(values, { setSubmitting }) => {
                        save(values, setSubmitting);
                    }}>
                    {({
                        submitForm
                    }) => (
                            <Form className={classes.form}>
                                <Box component={Paper} height="100%" display="flex" flexDirection="column">
                                    <Box>
                                        <Progress sections={data.visit.questionnaire.sections.map((section: any, index: number) => section.title)}
                                            activeSection={activeStep} />
                                    </Box>
                                    <Box flexGrow={1} >
                                        <QuestionnaireForm visitId={visitId} {...data.visit.questionnaire} activeSection={activeStep} />
                                    </Box>
                                    <Box className={classes.actionsContainer}>
                                        <Button disabled={activeStep === 0} onClick={(e) => handleBack(submitForm)}>
                                            Vorige
                                         </Button>
                                        {activeStep < data.visit.questionnaire.sections.length  &&
                                            <Button variant="contained" color="secondary" onClick={(e) => handleNext(submitForm)}>
                                                Volgende
                                         </Button>
                                        }
                                        {activeStep === data.visit.questionnaire.sections.length  &&
                                            <PdfViewer 
                                            variant="contained" 
                                            color="secondary" 
                                            visitId={data.visit.id}>
                                            Afronden
                                            </PdfViewer>

                                        //     <Button onClick={(e) => handleComplete(submitForm)}>
                                                
                                        //  </Button>
                                        }
                                    </Box>
                                </Box>
                            </Form>)
                    }
                </Formik>
            }
        </>
    );
};
