import React, { FunctionComponent } from 'react';
import { gql, useMutation } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import { Grid, Dialog, DialogTitle, DialogActions, DialogContent, Typography, CircularProgress } from '@material-ui/core';
import { Formik, FormikHelpers, Field, Form } from "formik";
import { TextField } from 'formik-material-ui';
import { KeyboardDateTimePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { SearchAddress } from "../address/search";
import { PickQuestionnaire } from "../questionnaires/pick";

export type NewVisitProps = {
    isOpen: boolean
    handleClose: () => void
};


export const ADD_VISIT = gql`
mutation AddVisit ($visit: NewVisit!){
    addVisit(visit :$visit){
      id
      date
    }
  }
`;

export const NewVisit: FunctionComponent<NewVisitProps> = (props: NewVisitProps) => {

    const [addVisit] = useMutation(ADD_VISIT, {
        errorPolicy: 'all',
        update(cache, { data: { addVisit } }) {
            cache.modify({
                fields: {
                    visits(existingVisits = []) {
                        const newVisitRef = cache.writeFragment({
                            data: addVisit,
                            fragment: gql`
                    fragment NewVisit on Visit {
                      id
                      date
                      ordernumber
                    }
                  `
                        });
                        return [...existingVisits, newVisitRef];
                    }
                }
            });
        }
    });

    const save = async (values: any, helpers: FormikHelpers<{}>) => {
        helpers.setSubmitting(true);
        var v = {
            addressId: values.addressId,
            ordernumber: values.ordernumber,
            questionnaireId: values.questionnaireId,
            date: values.date.toISOString()
        }

        await addVisit({ variables: { visit: v } });
        await new Promise(r => setTimeout(r, 1000));
        helpers.setSubmitting(false);
        helpers.resetForm();
        props.handleClose();
    };

    return (
        <Formik initialValues={{}} onSubmit={(values, helpers) => save(values, helpers)}>
            {({ submitForm, isSubmitting }) => (
                <Dialog open={props.isOpen} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Nieuwe afspraak</DialogTitle>
                    <DialogContent>
                        {!isSubmitting &&
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field component={TextField} fullWidth required label="ordernummer" name="ordernumber" type="text" variant="outlined"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field component={PickQuestionnaire}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >

                                            <Field component={KeyboardDateTimePicker} fullWidth variant="outlined" label="datum" name="date"  disablePast ampm={false} format="dd/MM/yyyy HH:mm" />

                                        </MuiPickersUtilsProvider>
                                    </Grid>


                                    {/* <Field component={TextField} fullWidth label="comissie" name="commission" type="text" /> */}
                                    {/* <Field component={TextField} fullWidth label="datum" name="date" type="datetime-local" /> */}

                                </Grid>
                                <br />
                                <br />
                                <Typography variant="h6" gutterBottom>
                                    Adres
                                </Typography>
                                <Grid container spacing={2}>

                                    <Field component={SearchAddress} />


                                </Grid>

                            </Form>
                        }
                        {isSubmitting &&
                            <CircularProgress color="secondary" />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleClose} color="primary">
                            Annuleren
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => submitForm()}
                        >
                            Opslaan
                            </Button>
                    </DialogActions>
                </Dialog>)}
        </Formik>
    );
};