import React, { FunctionComponent } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment } from "@material-ui/core";
import { useField } from "formik";
import { Question as QuestionModel } from "../../models/questionnaire";

type DimensionsProps = QuestionModel & {
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            backgroundColor: "white"
        },
        dimension: {
            marginTop: theme.spacing(1)
        }
    }),
);

export const Dimensions: FunctionComponent<DimensionsProps> = (props: DimensionsProps) => {
    const [ widthField ] = useField(props.id+".width");
    const [ depthField ] = useField(props.id+".depth");
    const [ heightField ] = useField(props.id+".height");

    const classes = useStyles();

    return (
        <>
            <Grid item sm={9}>
                <Typography>
                    {props.text}
                </Typography>
            </Grid>
            <Grid item sm>
                <FormControl variant="outlined" className={classes.dimension} fullWidth>
                    <InputLabel shrink disableAnimation className={classes.label} htmlFor={props.id+".width"} variant="outlined">breedte</InputLabel>
                    <OutlinedInput
                        {...widthField}
                        name={props.id+".width"}
                        endAdornment={<InputAdornment position="end"> cm </InputAdornment>}
                        label={props.prefix}

                    />
                </FormControl>
                <FormControl variant="outlined" className={classes.dimension} fullWidth>
                    <InputLabel shrink disableAnimation className={classes.label} htmlFor={props.id+".depth"} variant="outlined">diepte</InputLabel>
                    <OutlinedInput
                        {...depthField}
                        name={props.id+".depth"}
                        endAdornment={<InputAdornment position="end"> cm </InputAdornment>}
                        label={props.prefix}

                    />

                </FormControl>
                <FormControl variant="outlined" className={classes.dimension} fullWidth >
                    <InputLabel shrink disableAnimation className={classes.label} htmlFor={props.id+".height"} variant="outlined">hoogte</InputLabel>
                    <OutlinedInput
                        {...heightField}
                        name={props.id+".height"}
                        endAdornment={<InputAdornment position="end"> cm </InputAdornment>}
                        label={props.prefix}

                    />

                </FormControl>
            </Grid>
        </>);
};