import React, { FunctionComponent } from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Paper, Avatar, Typography,  Button } from "@material-ui/core";
import { Face as FaceIcon, LockOpen as LockOpenIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        grid: {
            minHeight: '100vh',
        },
        paper: {
            alignItems: "center",
            overflowWrap: "break-word",
            padding: theme.spacing(2),
        },
        avatar: {
            margin: "auto",
            width: theme.spacing(12),
            height: theme.spacing(12),
        },
        face: {
            width: "80%",
            height: "80%"
        },
        typography: {
            margin: "auto",
            padding: theme.spacing(2),
            maxWidth:  theme.spacing(20),
            textAlign: "center"
        },
        button: {
            margin: theme.spacing(1),
        },
    }),
);


export const Login: FunctionComponent = () => {
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Paper className={classes.paper}>
                    <Avatar alt="Anonymous" className={classes.avatar}>
                        <FaceIcon className={classes.face} />
                    </Avatar>
                    <Typography className={classes.typography}>
                        login met jouw account om toegang te krijgen to je planning en het invullen van inmeet formulieren.
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<LockOpenIcon />}
                        href={`/.auth/login/google?post_login_redirect_uri=/app/visits`}
                    >
                        login met google
                    </Button>

                </Paper>
            </Grid>
        </div>

    );
};
