import React, { FunctionComponent } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { useFormikContext } from "formik";

const GET_QUESTIONNAIRES = gql`
query GetQuestionnaires{
    questionnaires{
       id
      name
    }
  }
`;

export const PickQuestionnaire: FunctionComponent = () => {
    const { setFieldValue } = useFormikContext();
    const { loading, data } = useQuery(GET_QUESTIONNAIRES, {onCompleted: (data: any) => {
        if(data.questionnaires) {
            const d = data.questionnaires.find((q: any) => q.name === "standaard");
            if(d){
                setFieldValue("questionnaireId", d.id);
            }
        }
    }});

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFieldValue("questionnaireId", event.target.value as string);
    };

    return (
        <>
            {!loading &&
            <FormControl fullWidth variant="outlined" >
            <InputLabel id="demo-simple-select-label">formulier</InputLabel>
            <Select
              labelId="demo-simple-select-label"
                 onChange={handleChange} name="formulier">
                    {data.questionnaires.map((questionnaire: any) => <MenuItem value={questionnaire.id}>{questionnaire.name}</MenuItem>)}
                </Select>
                </FormControl>
            }
        </>
    );
};