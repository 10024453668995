import React, { FunctionComponent, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { ButtonGroup, IconButton, Button, Backdrop, CircularProgress, ButtonProps, Grid, Card } from "@material-ui/core";
import { Pagination } from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { useMutation, gql } from '@apollo/client';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const EXPORT_VISIT = gql`
mutation ExportVisit ($id: String!){
    exportVisit(id :$id){
        id
        pdfContent
    }
  }
`;

export type PdfViewerProps = ButtonProps & {
  visitId: string
};

export const PdfViewer: FunctionComponent<PdfViewerProps> = (props: PdfViewerProps) => {
  const classes = useStyles();

  const [state, setState] = useState({
    pages: {
      count: 0,
      active: 0
    },

  });
  const [pageNumber, setPageNumber] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [exportVisit] = useMutation(EXPORT_VISIT);
  const [content, setContent] = useState<string>();

  function onDocumentLoadSuccess({ numPages }: any) {
    setState({
      ...state,
      pages: {
        count: numPages,
        active: 0
      }
    });
  }

  const handleClose = () => {
    setOpen(false);
  };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  const handleComplete = async (submitForm: any) => {
    setOpen(true);

    var response = await exportVisit({
      variables: {
        id: props.visitId,
      }
    });

    if (response.data) {
      setContent(response.data.exportVisit.pdfContent);
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  return (
    <>
      <Button {...props} onClick={handleComplete}>
        {props.children}
      </Button>

      <Backdrop className={classes.backdrop} open={open}>
        {!content &&
          <CircularProgress color="inherit" />
        }
        {content &&
          <Card>
            <Grid container direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <ButtonGroup disableElevation variant="contained" color="primary">
                  <IconButton color="secondary" aria-label="download">
                    <GetAppIcon />
                  </IconButton>
                  <IconButton color="secondary" aria-label="close" onClick={handleClose}>
                    <CancelPresentationIcon />
                  </IconButton>
                </ButtonGroup>
              </Grid>
              <Grid item >
                <Document
                  file={"data:application/pdf;base64," + content}
                  onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
              </Grid>
              <Grid item >
                <Pagination count={state.pages.count} variant="outlined" color="primary" page={pageNumber} onChange={handleChange} />
              </Grid>
            </Grid>
          </Card>
        }
      </Backdrop>
    </ >
  );
};