import React, { FunctionComponent } from "react";
import { Divider } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Section as SectionModel } from "../models/questionnaire";
import { Question } from "./question";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        question: {
            margin: theme.spacing(1)
        },
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);


export type SectionProps = SectionModel & {
};

export const Section: FunctionComponent<SectionProps> = (props: SectionProps) => {
    const classes = useStyles();
    return props.questions
        ? <>
            {props.questions.map((question, index) => (
                <>
                    {index === 0 &&
                        <Divider className={classes.divider} />
                    }
                    <div key={question.id} className={classes.question}>
                        <Question {...question} />
                    </div>
                </>))
            }
        </>
        : <></>
        ;
};
