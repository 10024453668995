import React, { FunctionComponent, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Box, AppBar, Toolbar, IconButton, Drawer, Avatar, List, ListItem, ListItemAvatar, ListItemText, Divider } from "@material-ui/core";
import { Menu as MenuIcon, ExitToApp as SignOutIcon } from "@material-ui/icons";
import { Visits } from "../components/visits/list";
import { Visit } from "../components/visit";
import { Profile } from "../components/users/profile";
import { CalendarToday } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex"
        },
        content: {
            margin: theme.spacing(2),
        },
        avatar: {
            margin: "auto",
            width: theme.spacing(12),
            height: theme.spacing(12),
            marginTop: theme.spacing(4),
        },
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
    })
);


export const DefaultLayout: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();

    const [visible, setVisible] = useState(false);

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setVisible(open);
    };

    const navigate = (route: string) => {
        setVisible(false);
        history.push(route);
    };

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>

                    <Drawer open={visible} onClose={toggleDrawer(false)}>
                        <div className={classes.list}>
                            <Avatar className={classes.avatar} src="/static/images/avatar/1.jpg" />
                            <Profile />
                            <IconButton aria-label="signout" >
                                <   SignOutIcon />
                            </IconButton>
                            <Divider />
                            <List>
                                <ListItem button key="planning" onClick={(e) => navigate("/app/visits")}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CalendarToday />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="planning" />
                                </ListItem>
                            </List>
                        </div>
                    </Drawer>
                </AppBar>
            </Box>
            <Box flexGrow={1} className={classes.content}>
                <Route exact path="/app/visits" component={Visits} />
                <Route path="/app/visits/:visitId/questionnaire/:questionnaireId" component={Visit} />
            </Box>
        </Box>
    );
}

export default DefaultLayout;
