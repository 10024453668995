import React, { FunctionComponent, useState, EventHandler, ChangeEvent } from "react";
import { useField } from "formik";
import { Grid, Typography, MenuItem, FormGroup } from "@material-ui/core";
import { Field } from "formik";
import { Select as SelectField} from "formik-material-ui";
import { Question } from "../question";
import { Question as QuestionModel, Choice } from "models/questionnaire";

type SelectProps = QuestionModel & {};

export const Select: FunctionComponent<SelectProps> = (props: SelectProps) => {
    const [field] = useField(props.id);
    const [choice, setChoice] = useState<Choice>({ "text": "" });

    const changed: EventHandler<any> = (event: ChangeEvent<any>) => {
        if (props.choices) {
            let c: Choice | undefined = props.choices.find(c => c.text === event.target.value);
            if (c) {
                setChoice(c);
            }
        }

        field.onChange(event);
    };

    return (
        <>
            <Grid item sm={9}>
                <Typography>
                    {props.text}
                </Typography>
            </Grid>
            <Grid item sm>
                <FormGroup>
                    <Field component={SelectField} name={props.id} inputProps={{ onChange: changed}} variant="outlined"> 
                        {props.choices &&
                            props.choices.map((c, index) => {
                                return <MenuItem key={c.text} value={c.text}>{c.text}</MenuItem>;
                            })
                        }
                    </Field>
                </FormGroup>
            </Grid>
            {choice.question &&
                <Question key={choice.question.id} {...choice.question} />
            }
        </>
    );
};