import React, { FunctionComponent, useState, useEffect } from "react";
import axios from 'axios';

export const Profile: FunctionComponent = () => {

    const [data, setData] = useState({ userId: "", userDetails: "", roles: [] });

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios("/.auth/me");

            setData(result.data.clientPrincipal);
        };

        fetchData();
    }, []);

    return (<div>
        {data.userDetails}
    </div>);
};