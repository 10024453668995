import React, { FunctionComponent } from "react";
import { Question as QuestionModel, QuestionType } from "../models/questionnaire";
import { Grid, Divider } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Switch, TextField, Select } from "./forms";
import { Dimensions } from "./forms/dimensions";
import { Appliances } from "./forms/appliances";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

export type QuestionProps = QuestionModel & {};

export const Question: FunctionComponent<QuestionProps> = (props: QuestionProps) => {
    const classes = useStyles();

    const getInput = (type: QuestionType) => {
        switch (type.toUpperCase()) {
            case QuestionType.Text:
                return <TextField {...props} />;
            case QuestionType.Switch:
                return <Switch {...props} />;
            case QuestionType.Select:
                return <Select {...props} />;
            case QuestionType.Dimensions:
                return <Dimensions {...props} />
            case QuestionType.Appliances:
                return <Appliances {...props} />
            default:
                return <TextField {...props} />;
        }
    }

    return (
        <>
            <Grid component="label" container alignItems="center"  justify="space-between">
                {getInput(props.type)}
            </Grid>
            <Divider className={classes.divider} />
        </>
    );
};
