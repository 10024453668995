import React, { FunctionComponent, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import { Schedule, Today } from '@material-ui/icons';
import { NewVisit } from "./visits/new";
type ActionsProps = {

};

export const Actions: FunctionComponent<ActionsProps> = (props: ActionsProps) => {

    const [state, setState] = useState({
        open: false,
        show: false
    });

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const handleOpen = () => {
        setState({ ...state, open: true });
    };

    const handleOpenDialog = () => {
        setState({ ...state, open: false, show: true });
    };

    const handleCloseDialog = () => {
        setState({ ...state, open: false, show: false });
    };

    return (
        <SpeedDial
            ariaLabel="action menu"
            icon={<SpeedDialIcon color="secondary" openIcon={<Schedule />} />}
            
            onClose={handleClose}
            onOpen={handleOpen}
            open={state.open}
            direction="right">

            <SpeedDialAction
                key="new"
                icon={<Today />}
                tooltipTitle="new visit"
                onClick={handleOpenDialog}
            />
            <NewVisit isOpen={state.show} handleClose={handleCloseDialog} />
        </SpeedDial>
    );
};



