import React, { FunctionComponent, ReactNode } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

type ThemeProps = {
    children: ReactNode,
};

export const Theme: FunctionComponent<ThemeProps> = (props: ThemeProps) => {

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#5e5e5e",
      main: "#343434",
      dark: "#0d0d0d",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff5541",
      main: "#e00716",
      dark: "#a50000",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#e00716",
      },
    },
  }
});

return (
    <ThemeProvider theme={theme} children={props.children}/>
);
}

