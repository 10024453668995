import React, { FunctionComponent } from "react";
import { useField } from "formik";
import { Grid, Typography,FormControl, OutlinedInput, InputAdornment,InputLabel  } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Question as QuestionModel } from "../../models/questionnaire";

export type TextFieldProps = QuestionModel & {
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            backgroundColor: "white"
        },
    }),
);

export const TextField: FunctionComponent<TextFieldProps> = (props: TextFieldProps) => {
    const [field] = useField(props.id);
    const classes = useStyles();

    return (
        <>
            <Grid item sm={9}>
                <Typography>
                    {props.text}
                </Typography>
            </Grid>
            <Grid item sm={3}>
            <FormControl variant="outlined" fullWidth>
            <InputLabel shrink disableAnimation className={classes.label} htmlFor={props.id} variant="outlined">{props.prefix}</InputLabel>
          <OutlinedInput
           {...field}
            name={props.id}
            endAdornment={<InputAdornment position="end"> {props.suffix} </InputAdornment>}
            label={props.prefix}
            
          />
          
        </FormControl>
 
            </Grid>
        </>);
};