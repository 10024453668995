import React, { FunctionComponent } from "react";
import { Section } from "./section";
import { Questionnaire as QuestionnaireModel } from "../models/questionnaire";
//import { FrontDrawing } from "./drawings/front";



export type QuestionnaireProps = QuestionnaireModel & {
    visitId: string
    activeSection: number
} ;

export const QuestionnaireForm: FunctionComponent<QuestionnaireProps> = (props: QuestionnaireProps) => {
    return (
        <>
            {props.activeSection < props.sections.length &&
                <Section key={props.activeSection} {...props.sections[props.activeSection]} />
            }
        </ >
    );
};