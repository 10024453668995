import React, { FunctionComponent } from "react";
import { useFormikContext, useField, Field, FieldArray } from "formik";
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, Button } from "@material-ui/core";
import { TextField } from 'formik-material-ui';
import { Question as QuestionModel } from "models/questionnaire";

export type AppliancesProps = QuestionModel & {
};

export const Appliances: FunctionComponent<AppliancesProps> = (props: AppliancesProps) => {
    const [field] = useField(props.id);
    const {values } = useFormikContext<any>();

    return (
        <>
            <FieldArray {...field}
                render={arrayHelpers => 
                   <>
                   <div>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e : React.MouseEvent) => {
                                arrayHelpers.push({
                                    appliance: "siemens",
                                    manufacturer: "",
                                    dimensions: "",
                                    typenumber: "",
                                    fdnumber: "",
                                });
                            }}
                        >
                            add
                    </Button>
                    </div>
                    <Grid item xs={12}>
                        <Table onClick={(e) => { console.log(e)}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Apparaat</TableCell>
                                    <TableCell align="left">merk</TableCell>
                                    <TableCell align="left">Nismaat </TableCell>
                                    <TableCell align="left">Typenummer</TableCell>
                                    <TableCell align="left">FD-nr.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {values[props.id] && values[props.id].map((row: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell align="left"><Field component={TextField} name={`${props.id}.${index}.appliance`} /></TableCell>
                                        <TableCell align="left"><Field component={TextField} name={`${props.id}.${index}.manufacturer`} /></TableCell>
                                        <TableCell align="left"><Field component={TextField} name={`${props.id}.${index}.dimensions`} /></TableCell>
                                        <TableCell align="left"><Field component={TextField} name={`${props.id}.${index}.typenumber`} /></TableCell>
                                        <TableCell align="left"><Field component={TextField} name={`${props.id}.${index}.fdnumber`} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    </>
                }
            />


        </>);
};