import React, { FunctionComponent, useRef, RefObject } from "react";
import { Grid, TextField, Button, List, ListItem, ListItemText } from "@material-ui/core";

import { useFormikContext } from "formik";
import { useLazyQuery, gql } from "@apollo/client";

const ADDRESSES = gql`
query ListAddresses ($input: ListAddress!){
    addresses(input :$input){
        id,
        displayName
      }
  }
`;


export const SearchAddress: FunctionComponent = () => {

    const zipcode: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const number: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

    const [getAddresses, { loading, data }] = useLazyQuery(ADDRESSES, {
        onCompleted: (data: any) => {
            if (data.addresses && data.addresses.length === 1) {
                setFieldValue("addressId", data.addresses[0].id)
            }
        }
    });

    const { setFieldValue } = useFormikContext();
    const search = () => {
        if (zipcode.current && number.current) {
            getAddresses({
                variables: {
                    input: {
                        zipCode: zipcode.current.value,
                        number: parseInt(number.current.value)
                    }
                }
            });
        }
    };

    return (
        <>
            
            
                <Grid item xs={12}>
                    <TextField inputRef={zipcode} variant="outlined" required fullWidth label="postcode" name="zipcode" type="text" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField inputRef={number} required fullWidth variant="outlined" label="huisnummer" name="number" type="number" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label="toevoeging" variant="outlined" name="suffix" type="text" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={() => search()}
                        variant="contained"
                        color="secondary">
                        Zoeken
                    </Button>
                </Grid>
            



            

            {!loading && data &&
                <List>
                    {data.addresses.map((address: any) => (
                        <ListItem button onClick={(event) => setFieldValue("addressId", address.id)}>
                            <ListItemText key={address.id} primary={address.displayName} />
                        </ListItem>
                    ))}
                </List>
            }
        </>

    );
};