import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLazyQuery, gql } from '@apollo/client';

import { Box, Grid, Paper, List, ListItem, ListItemText, ListItemAvatar, ListItemIcon, Avatar, CircularProgress, InputAdornment } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Schedule, DoneAll, Edit, Assignment } from '@material-ui/icons';
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CalendarToday } from '@material-ui/icons';

import { Planning } from "../planning";
import { Actions } from "../actions";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        datePicker: {
            padding: theme.spacing(2)
        },
        actionsContainer: {
            textAlign: "right",
            margin: theme.spacing(2)
        },
    }),
);

export const LIST_VISITS = gql`
query ListVisits ($range: ListVisits!){
    visits(range :$range){
      id
      date,
      questionnaireId,
      address {
        displayName,
        street,
        number,
        zipCode,
        city
      },
      isCompleted
    }
  }
`;


export const Visits: FunctionComponent = () => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [getVisits, { loading, data }] = useLazyQuery(LIST_VISITS);

    const onDateChanged = (date: MaterialUiPickersDate) => {
        if (date) {
            setSelectedDate(date);
            // var from: Date = new Date(date);
            // from.setDate(from.getDate()-3);
            // var to: Date = new Date(date);
            // to.setDate(to.getDate()+3);
            // getVisits({
            //     variables: {
            //         range: {
            //             from: from.toISOString(),
            //             to: to.toISOString()
            //         }
            //     }
            // });
        }
    };

    useEffect(() => {
            var from: Date = new Date(selectedDate);
            from.setDate(from.getDate()-3);
            var to: Date = new Date(selectedDate);
            to.setDate(to.getDate()+3);
            getVisits({
                variables: {
                    range: {
                        from: from.toISOString(),
                        to: to.toISOString()
                    }
                }
            });
      }, [selectedDate, getVisits]);

    return (
        <Box component={Paper} height="100%" display="flex" flexDirection="column">
            <Box flexGrow={1} >
                <Grid container>
                    
                    <Grid item xs={8}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <DatePicker
                             className={classes.datePicker} 
                                autoOk
                                inputVariant="outlined"
                                value={selectedDate}
                                onChange={onDateChanged}
                                animateYearScrolling
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                         <CalendarToday />
                                      </InputAdornment>
                                   )
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        {!loading && data &&
                            <List>
                                {data.visits.map((visit: any, index: number) => (
                                    <ListItem button key={visit.id}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                {visit.isCompleted &&
                                                    <DoneAll />
                                                }
                                                {!visit.isCompleted &&
                                                    <Schedule />
                                                }

                                            </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText primary={visit.date} secondary={visit.address.displayName} />

                                        {!visit.isCompleted &&
                                            <>
                                                <Link to={`/app/visits/${visit.id}/questionnaire/${visit.questionnaireId}`}>

                                                    <ListItemIcon>
                                                        <Assignment />
                                                    </ListItemIcon>
                                                </Link>
                                                <Link to={`/app/visits/${visit.id}`}>
                                                    <ListItemIcon>
                                                        <Edit />
                                                    </ListItemIcon>
                                                </Link>
                                            </>}
                                    </ListItem>))
                                }
                            </List>
                        }

                        {loading &&
                            <CircularProgress color="secondary" />
                        }
                    </Grid>
                    <Grid item xs={4}>
                    {!loading && data &&
                        <Planning activeDay={selectedDate} visits={data.visits} />
                    }
                    </Grid>`
                </Grid>
            </Box>
            <Box className={classes.actionsContainer}>
                <Actions />
            </Box>
        </Box>
    );
};
