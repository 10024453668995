import React from "react";
import { Theme } from "./theme";
import { Client } from "./api";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { LoginLayout } from "./layouts/login";
import { DefaultLayout } from "./layouts/default";
import './App.css';

function App() {



  return (
    <Router>
      <ApolloProvider client={Client}>
        <Theme>
          <div className="App">
            <Switch>
              <Route exact path={["/", "/(login)"]} component={LoginLayout} />
              <Route component={DefaultLayout} />
            </Switch>
          </div>
        </Theme>
      </ApolloProvider>
    </Router>
  );
}

export default App;
