import React, { FunctionComponent, useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Timeline, TimelineContent, TimelineItem, TimelineSeparator, TimelineOppositeContent, TimelineDot, TimelineConnector } from "@material-ui/lab";

type PlanningProps = {
    activeDay: Date
    visits: any[]
};

export const Planning: FunctionComponent<PlanningProps> = (props: PlanningProps) => {

    const [days, setDays] = useState<Date[]>([])
    const [day, setDay] = useState<number>(props.activeDay.getDay());

    useEffect(() => {
        let from: Date = new Date(props.activeDay);
        from.setDate(from.getDate()-3);
        
        let result: Date[] = [];

        for(var i = 0; i < 7;i++){
            let d:Date = new Date(from);
            d.setDate(d.getDate()+i);
            result.push(d);
            console.log(result);
        }
        console.log(result);
        setDays(result);
    }, [props.activeDay]);

    const getTimelineDotColor = (dayNumber: number) => {
        return day === dayNumber ? "secondary" : "grey";
    };

    const setActiveDay = (dayNumber: number) => {
        setDay(dayNumber);
    }

    const visitCount = (date: Date) : string => {
        let count: number = 0;
        if(props.visits) {
            count = props.visits.filter(x => new Date(x.date).setHours(0,0,0,0) === date.setHours(0,0,0,0)).length;
        }
        return count === 0 ? "no visits" : count > 1 ? `${count} visits` : "1 visit";
    } 

    return (
    <Timeline>
        {days.map((day, index) => (
            <div key={day.toISOString()} onClick={e => setActiveDay(index)}>
            <TimelineItem >
                <TimelineOppositeContent>
                    <Typography color="textSecondary">{day.toDateString()}</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color={getTimelineDotColor(index)}  />
                { index !== 6 &&
                    <TimelineConnector />
                }
                </TimelineSeparator>
                <TimelineContent>
                    <Typography color="textPrimary">{visitCount(day)}</Typography>
                </TimelineContent>
            </TimelineItem>
            </div>
        ))}
    </Timeline>);
};



