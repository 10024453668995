"usse strict";

// tslint:disable-next-line:interface-name
export interface Visit {
    ordernumber?: string;
    commission?: string;
    date?: Date;
    arrival?: Date;
    departure: Date;
    answers: Record<string, Answer>;
}

// tslint:disable-next-line: interface-name
export interface Answer {
    id: number;
    value?: any;
}

// tslint:disable-next-line:interface-name
export interface Questionnaire {
    logo?: string;
    sections: Section[];
}

// tslint:disable-next-line:interface-name
export interface Section {
    id: number;
    title: string;
    questions: Question[];
}

// tslint:disable-next-line:interface-name
export interface Question {
    id: string;
    text: string;
    value?: string;
    type: QuestionType;
    prefix?: string;
    suffix?: string;
    choices?: Choice[];
}

export interface Choice {
    text: string;
    question?: Question;
}

export enum QuestionType {
    Text = "TEXT",
    Number = "NUMBER",
    Select = "SELECT",
    Switch = "SWITCH",
    Dimensions = "DIMENSIONS",
    Appliances = "APPLIANCES",
}