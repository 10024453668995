import React, {FunctionComponent} from "react";
import { Route, Redirect } from "react-router-dom";
import { Login } from "../components/login";


export const LoginLayout: FunctionComponent = () => {
    return (
        <div className="container">
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route path="/login" component={Login} />
        </div>
    );
}

export default LoginLayout;
