import React, { FunctionComponent } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

type ProgressProps = {
    activeSection: number,
    sections: string[]
};

export const Progress: FunctionComponent<ProgressProps> = (props: ProgressProps) => {
    return <Stepper activeStep={props.activeSection}>
           {props.sections.map((section, index) => (
                <Step key={section}>

                    <StepLabel>
                        {(props.activeSection === index || props.activeSection+1 === index || props.activeSection-1 === index) &&
                            <span>{section}</span>
                        }
                    </StepLabel>
                </Step>
            ))}
            </Stepper>;
};
