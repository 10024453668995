import React, { FunctionComponent, useState, ChangeEvent } from "react";
import { useField, useFormikContext } from "formik";
import { Grid, Typography, FormGroup, Slider } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Question } from "../question";
import { Question as QuestionModel } from "../../models/questionnaire";

type SwitchProps = QuestionModel & {};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: theme.spacing(1),
            width: theme.spacing(5),
            left: theme.spacing(2)
        },
        track: {
            height: "14px",
            borderRadius: 7,
        },
        rail: {
            height: "14px",
            borderRadius: 7,
        },
        railNotSet: {
            height: "14px",
            borderRadius: 7,
        },
        thumb: {
            marginTop: "-3px",
            marginLeft: "-10px",
            width: "20px",
            height: "20px",
            boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            borderRadius: "50%",
            transition: "left 0.1s ease-in",
            backgroundColor: theme.palette.common.white,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
        },
        mark: {
            display: "none"
        },
        no: {
            //backgroundColor: theme.palette.secondary.main
        },
        notset: {
            marginLeft: "10px",
            //backgroundColor: grey[800]
        },
    }),
);



export const Switch: FunctionComponent<SwitchProps> = (props: SwitchProps) => {
    const [field] = useField(props.id);
    const { setFieldValue } = useFormikContext();
    const classes = useStyles();

    const changed = (event: ChangeEvent<any>, value: number | number[]) => {
        if (value === -1) {
            setFieldValue(field.name, false);
            field.value = false
        }
        if (value === 0) {
            setFieldValue(field.name, null);
        }
        if (value === 1) {
            setFieldValue(field.name, true);
        }
    };

    const marks = [
        {
            value: -1,
            label: "NEE",
        },
        {
            value: 0,
            label: "",
        },
        {
            value: 1,
            label: "JA",
        }
    ];

    const valuetext = (value: number): string => {
        if (value === -1) return "JA";
        if (value === 1) return "NEE"

        return ""
    }

    const defaultValue = (value: boolean): number => {
        if (value === false) return -1;
        if (value === true) return 1;

        return 0
    }

    const [value] = useState(defaultValue(field.value))
    

    return (
        <>
            <Grid item sm={9}>
                <Typography>
                    {props.text}
                </Typography>
            </Grid>
            <Grid item sm={3}>
                <FormGroup>
                    <Slider
                        defaultValue={value}
                        getAriaValueText={valuetext}
                        valueLabelDisplay="off"
                        aria-labelledby="discrete-slider-custom"
                        track={false}
                        color={defaultValue(field.value) === 0 ? "primary" : "secondary"}
                        onChange={changed}
                        classes={{
                            root: classes.root,
                            rail: classes.rail,
                            track: classes.track,
                            thumb: classes.thumb,
                            mark: classes.mark
                        }}
                        step={1}
                        min={-1}
                        max={1}
                        marks={marks}
                    />

                </FormGroup>
            </Grid>

            {field.value === true && props.choices && props.choices[0].question &&
                <Question key={props.choices[0].question.id} {...props.choices[0].question} />
            }
            {field.value !== null && !field.value && props.choices && props.choices[1].question &&
                <Question key={props.choices[1].question.id} {...props.choices[1].question} />
            }
        </>
    );
};